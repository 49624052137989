import { Model, Store, OrderedStore } from 'store/Base';
import { computed, observable } from 'mobx';
import { Activity } from './Activity';
import { Trip } from './Trip';
import { PurchaseInvoiceSelfBillTripActivityLineStore } from './PurchaseInvoiceSelfBillTripActivityLine';
import PurchaseInvoiceStatus, { NOT_INVOICEABLE, NOT_PAID } from './enums/PurchaseInvoiceStatus';
import { PROOF_OF_DELIVERY, ORDER_INVOICING } from './enums/DocumentType'
import { OPEN } from './enums/DocumentStatus';
import { omit } from 'lodash';

export class TripActivity extends Model {
    static backendResourceName = 'trip_activities';

    @observable id = null;
    @observable ordering = 0;
    @observable plannedKm = 0;
    @observable roadPricing = 0;
    @observable additionalCost;
    @observable purchasePrice = null;
    @observable purchaseInvoiceStatus = NOT_PAID;
    @observable podStatus = OPEN;
    @observable invoicingStatus = OPEN;

    relations() {
        return {
            trip: Trip,
            prevActivity: Activity,
            activity: Activity,
            purchaseInvoiceLines: PurchaseInvoiceSelfBillTripActivityLineStore
        };
    }

    triggerCalculateKm() {
        return this.api.post(`${this.url}trigger_calculate_km/`);
    }

    markAsNotInvoiceable() {
        this.purchaseInvoiceStatus = NOT_INVOICEABLE;
        this.setInput('purchaseInvoiceStatus', NOT_INVOICEABLE);
        return this.save({
            fields: ['purchaseInvoiceStatus']
        });
    }

    findLowestStatus = (models) => {
        if (models.length === 0) return null; // Return null if the array is empty

        const statusMap = {
            'rejected': 0,
            'pending': 1,
            'accepted': 2
        };

        let lowestStatus = models[0].status; // Initialize with the status of the first element

        models.forEach(model => {
            if (statusMap[model.status] < statusMap[lowestStatus]) {
                lowestStatus = model.status;
            }
        });

        return lowestStatus;
    };

    findHighestStatus = (models) => {
        if (models.length === 0) return null; // Return null if the array is empty

        const statusMap = {
            'rejected': 0,
            'pending': 1,
            'accepted': 2
        };

        let lowestStatus = models[0].status; // Initialize with the status of the first element

        models.forEach(model => {
            if (statusMap[model.status] > statusMap[lowestStatus]) {
                lowestStatus = model.status;
            }
        });

        return lowestStatus;
    };


    get companyPortalPOD() {
        return this.activity?.documents?.models.some(doc => doc.type === PROOF_OF_DELIVERY)
    }

    get companyPortalPODStatus() {
        return this.findHighestStatus(this.activity?.documents.models.filter(doc => doc.type === PROOF_OF_DELIVERY));
    }

    get companyPortalInvoiceStatus() {
        return this.findHighestStatus(this.activity?.documents.models.filter(doc => doc.type === ORDER_INVOICING));
    }

    @computed get invoiceStatusColorOverview() {
        switch (this.purchaseInvoiceStatus) {
            case PurchaseInvoiceStatus.NOT_PAID:
                return 'var(--red-300)';
            case PurchaseInvoiceStatus.IN_PROGRESS:
                return 'var(--orange-300)'
            case PurchaseInvoiceStatus.TRANSFERRED:
                return 'var(--blue-300)'
            case PurchaseInvoiceStatus.PAID:
                return 'var(--green-300)'

            default:
                return 'var(--gray-300)';
        }
    }

    toBackend(...args) {
        const data = super.toBackend(...args);

        return omit(data, 'pod_status', 'invoicing_status');
    }
}

export class UnorderedTripActivityStore extends Store {
    Model = TripActivity;
    static backendResourceName = 'trip_activities';

    finalizeLoads() {
        return this.api.post(`/${UnorderedTripActivityStore.backendResourceName}/finalize_loads/`);
    }
}

export const TripActivitiesStore = OrderedStore(UnorderedTripActivityStore, 'ordering');
