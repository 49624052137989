import { Model, Store, Casts } from 'store/Base';
import { action, computed, observable } from 'mobx';
import { Location } from './Location';
import { Truck } from './Truck';
import { Trailer } from './Trailer';
import { ServiceSubtype } from './ServiceSubtype';
import moment from 'moment';
import { DocumentStore } from './Document';
import { TaskStore } from './Task';
import { ActivityStore } from './Activity';
import { INVOICE } from 'store/enums/DocumentType'

export class Service extends Model {
    static backendResourceName = 'service';
    static omitFields = [
        'estimatedDuration',
        'assetType',
        'assetFleetNumber',
        'priority',
        'effectiveExpiredAt',
    ];

    @observable id = null;
    @observable subject = null;
    @observable explanation = null;
    @observable plannedAt = null;
    @observable estimatedDue = null;
    @observable executedAt = null;
    @observable expiredAt = null;
    @observable assetType = null;
    @observable assetFleetNumber = null;
    @observable priority = null;
    @observable effectiveExpiredAt = null;

    casts() {
        return {
            plannedAt: Casts.datetime,
            estimatedDue: Casts.datetime,
            executedAt: Casts.date,
            expiredAt: Casts.date,
            effectiveExpiredAt: Casts.date,
        };
    }

    relations() {
        return {
            serviceSubtype: ServiceSubtype,
            location: Location,
            truck: Truck,
            trailer: Trailer,
            documents: DocumentStore,
            tasks: TaskStore,
            activities: ActivityStore,
        }
    }

    // Unused but lets leave it for mobx to trigger view updates
    @observable _estimatedDuration = null;
    set estimatedDuration(value) {
        this._estimatedDuration = value;
    }
    @computed get estimatedDuration() {
        if (!this.plannedAt || !this.estimatedDue) {
            return null;
        }
        else {
            const start = moment.isMoment(this.plannedAt) ? this.plannedAt : moment(this.plannedAt.toString());
            const end = moment.isMoment(this.estimatedDue) ? this.estimatedDue : moment(this.estimatedDue.toString());
            return moment.duration(end.diff(start, null, true)).asHours().toFixed(2);
        }
    }

    @computed get tagLabel() {
        return `S${this.id}`;
    }

    @computed get editUrl() {
        return `/admin/service/${this.id}/edit`;
    }

    @computed get files() {
        return this.documents?.models.filter(doc => doc.type !== INVOICE) ?? [];
    }

    @computed get invoices() {
        return this.documents?.models.filter(doc => doc.type === INVOICE) ?? [];
    }

    @action
    async linkActivity(...params) {
        // Separate calls to backend for these reasons:
        // - It is possible to do both together, but with saving relations like documents(which we do),
        //   it becomes multiput and also there are can be files in the payload,
        //   which complicates the link_activity endpoint with redundant code from binder.
        // - Even if we manage to cover all edge cases and do it in one endpoint,
        //   then multiput does not return modified object- only new id mappings.
        //   So we have to do another request to the api anyway to get our newly created activity.

        // Firstly save entire model if there are any changes
        if (this.hasUserChanges) {
            await this.save(...params);
        }

        // And then do linking
        // Create activity in the backend and get updated model in the response
        const data = await this.save({
            url: `${this.url}link_activity/`,
            onlyChanges: true,
            params: {
                'with': 'activities.location'
            }
        })
        // Add a formater, because BinderApi.saveModel wraps response into an object
        .then(this.api.__responseFormatter)
        // Map to a compatibile mobx-spine format- shame its not provided as built-in reusable method
        .then(res => ({
            data: res.data,
            repos: res.with,
            relMapping: res.with_mapping,
            reverseRelMapping: res.with_related_name_mapping,
        }));

        // And then parse with Model.fromBackend, so our view will get automatically updated with newly added activity!
        this.fromBackend(data);

        return data;
    }
}

export class ServiceStore extends Store {
    Model = Service;
    static backendResourceName = 'service';
}
