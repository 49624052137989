
export const CONTRACT = 'contract';
export const TERMS_AND_AGREEMENTS = 'terms_and_agreements';
export const SIGNED_WAGE_AGREEMENT = 'signed_wage_agreement';
export const LIABILITY_AND_INSURANCE = 'liability_and_insurance';
export const LICENSES = 'licenses';
export const INVOICE = 'invoice';
export const LETTERHEAD = 'letterhead';
export const EU_LICENSE_FOR_GOODS_TRANSPORTATION = 'eu_license_for_goods_transportation';
export const CMR_INSURANCE_POLICY = 'cmr_insurance_policy';
export const PROOF_OF_DELIVERY = 'proof_of_delivery';
export const ORDER_INVOICING = 'order_invoicing';

export const TYPES = [
    CONTRACT,
    TERMS_AND_AGREEMENTS,
    SIGNED_WAGE_AGREEMENT,
    LIABILITY_AND_INSURANCE,
    LICENSES,
    INVOICE,
    LETTERHEAD,
    EU_LICENSE_FOR_GOODS_TRANSPORTATION,
    CMR_INSURANCE_POLICY,
    PROOF_OF_DELIVERY,
    ORDER_INVOICING,
]

export default {
    CONTRACT,
    TERMS_AND_AGREEMENTS,
    SIGNED_WAGE_AGREEMENT,
    LIABILITY_AND_INSURANCE,
    LICENSES,
    INVOICE,
    LETTERHEAD,
    EU_LICENSE_FOR_GOODS_TRANSPORTATION,
    CMR_INSURANCE_POLICY,
    PROOF_OF_DELIVERY,
    ORDER_INVOICING,
 }
