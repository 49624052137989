import { computed, observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { TerminalTransporter } from 'store/TerminalTransporter';
import { TruckingCompany } from 'store/TruckingCompany';
import { Order } from 'store/Order';
import { Invoice } from 'store/Invoice';
import { Activity } from 'store/Activity';
import { getFileNameFromUrl } from 'component/Target';
import { User } from './User';

export class Document extends Model {
    static backendResourceName = 'document';

    @observable id = null;
    @observable file = null;
    @observable type = '';
    @observable status = null;
    @observable reasonOfRejection = '';

    relations() {
        return {
            terminalTransporter: TerminalTransporter,
            truckingCompany: TruckingCompany,
            order: Order,
            invoice: Invoice,
            activity: Activity,
            uploadedBy: User,
        }
    }

    @computed get previewUrl() {
        return this.file;
    }

    @computed get downloadUrl() {
        return `${this.file}&download=true`;
    }

    @computed get name() {
        return getFileNameFromUrl(this.file);
        // return `${this.id}`;
    }
}

export class DocumentStore extends Store {
    Model = Document;
    static backendResourceName = 'document';
}
